import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Nav, NavItem } from 'reactstrap';

class HeaderLinks extends Component {

  render() {
    return (
      <Nav className="ml-auto" navbar>
        <NavItem>
          <Link className="nav-link" to={'/section/focus'}>Focus</Link>
        </NavItem>      
        <NavItem>
          <Link className="nav-link" to={'/section/optimize'}>Optimize</Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link" to={'/section/target'}>Target </Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link" to="/section/engage">Engage</Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link" to="/section/solutions">Solutions</Link>
        </NavItem>
      </Nav>
    );
  }
}

export default withRouter(HeaderLinks);
