import React, { Component } from 'react';
import { connect } from 'react-redux';
import {BrowserRouter as Router, Route } from 'react-router-dom';
import { setCategories } from './redux/actions';
import axios from 'axios';
import ScrollToTop from 'react-router-scroll-top'

import Login from './components/Login';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './components/dashboard/Dashboard';
import Page from './components/page/Page';
import Report from './components/reports/Report';


class App extends Component {

  state = {
    disable_auth: false
  }

  componentDidMount() {
    const getCategories = axios.get(`${process.env.REACT_APP_WP_API}/wp-json/wp/v2/categories`);

    Promise.all([getCategories])
      .then( res => {
        this.props.setCategories(res[0].data)
      })
      .catch( err => console.log(err));
  }


  render() {

    if(this.props.is_auth || this.state.disable_auth){
      return (
        <Router>
          <ScrollToTop>
              <Header/>
              <Route exact path="/" component={Dashboard}></Route>
              <Route path="/section/:slug" component={Page}></Route>
              <Route path="/reports/:slug" component={Report}></Route>
              <Footer />
          </ScrollToTop>
        </Router>
      )
    }
    return <Login />
  }
}

const mapStateToProps = state => {
  return ({
    is_auth: state.auth.is_auth
  })
}

export default connect(mapStateToProps, { setCategories } )(App);
