import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Loading from '../Loading';
import PageTopics from './PageTopics';
import PageReports from './PageReports';
import NextSection from './NextSection';


export class Page extends Component {
  
  state = {
    page: {},
    isLoaded: false,
    slug: this.props.match.params.slug,
    id: null,
    category_id : null
  }

  componentDidMount() {
    const getPage = axios.get(`${process.env.REACT_APP_WP_API}/wp-json/wp/v2/pages?_embed&slug=${this.state.slug}`);

    Promise.all([getPage])
      .then( res => {
        this.setState({
          page: res[0].data[0],
          isLoaded: true
        });
      })
      .catch( err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.match.params.slug !== this.state.slug ){
      this.setState({isLoaded: false});
      const updatePage = axios.get(`${process.env.REACT_APP_WP_API}/wp-json/wp/v2/pages?_embed&slug=${nextProps.match.params.slug}`);

      Promise.all([updatePage])
      .then( res => {
        this.setState({
          page: res[0].data[0],
          isLoaded: true,
          slug: nextProps.match.params.slug 
        });
      })
      .catch( err => console.log(err));
    }
  }
  

  render() {
    console.log(this.state);

    const {isLoaded } = this.state;

    if(isLoaded){
      const {page, category_id, slug } = this.state;
      const imgObj = page._embedded['wp:featuredmedia'][0];
      const imgUrl = imgObj.source_url;
      let nextSection = false;
      if (page.slug === "focus") { nextSection = "optimize"}
      if (page.slug === "optimize") { nextSection = "target"}
      if (page.slug === "target") { nextSection = "engage"}
      if (page.slug === "engage") { nextSection = "solutions"}
      return (
        <div className="container-fluid px-0" style={{}} >

          <div className="row" style={{backgroundImage: `url(${imgUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div className="p-5" style={{backgroundColor: 'rgba(0, 0, 0, 0.75)', color: '#fff', maxWidth: '600px', minHeight: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <h2 style={{fontSize: '3rem', fontWeight: '900'}}>{page.title.rendered}</h2>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col mx-auto p-5 page-text" 
                dangerouslySetInnerHTML={{ __html: page.content.rendered }}
                style={{maxWidth: '800px', fontSize: '1.4rem', fontWeight:'300'}}
              ></div>
            </div>
          </div>

          <PageTopics page={page} />
          
          
          { slug !== 'focus' && slug !== 'solutions' && <PageReports page={page} category_id={category_id}/> }

          <div className="row" style={{ border: '1px solid #ccc'}}>
            <div className="col mx-auto p-5 wp-content" 
              dangerouslySetInnerHTML={{ __html: page.acf.content_lower }}
              style={{maxWidth: '800px', fontSize: '1.4rem', fontWeight:'300'}}
            ></div>
          </div>

          { nextSection && <NextSection nextSection={nextSection} /> }   
        </div>
        
      )
    }

    return ( <Loading />)
  }
}


const mapStateToProps = state => {
  return ({
    categories: state.wp_categories.categories
  })
}

export default connect(mapStateToProps, null)(Page)
