import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import Loading from '../Loading';
import TopicItem from './TopicItem';
import bgImg from '../../img/header-repeating-bg.jpg';



export class PageTopics extends Component {
  
  static propTypes = {
    page: PropTypes.object.isRequired
  }

  state = {
    topics: {},
    isLoaded: false,
    slug: this.props.page.slug,
    id: this.props.page.id
  }

  componentDidMount() {
    const getTopics = axios.get(`${process.env.REACT_APP_WP_API}/wp-json/wp/v2/pages?parent=${this.state.id}&orderby=menu_order&order=asc`);

    Promise.all([getTopics])
      .then( res => {
        this.setState({
          topics: res[0].data,
          isLoaded: true
        });
      })
      .catch( err => console.log(err));
  }


  render() {
    console.log(this.state);

    const {isLoaded, topics } = this.state;

    if(isLoaded){
      return (
        <div className="container-fluid p-5 mx-0" style={{backgroundImage: `url(${bgImg})`}} >
          <div className="row mb-4">
            <div className="col">
              <h2 style={{fontSize:'3rem', fontWeight: '100', textAlign: 'center' }}>Topics</h2>
            </div>
          </div>
          <div className="row my-5" style={{justifyContent: 'space-around'}}>
            {topics.map(topic => (
              <TopicItem key={topic.id} topic={topic}/>
            ))}
          </div>
        </div>
      )
    }

    return ( <Loading />)
  }
}

export default PageTopics
