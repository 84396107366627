import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


export class DashboardListItem extends Component {
  
  static propTypes = {
    element: PropTypes.object.isRequired
  }


  render() {
    const { title, excerpt, _embedded, slug } = this.props.element;
    const imgObj = _embedded['wp:featuredmedia'][0];
    const imgUrl = imgObj.source_url;
    let style = {
      wrapper: {
       margin: '0', 
       padding: '0'
      },
    }

    if(imgUrl) {style.wrapper.backgroundImage =  `url(${imgUrl})`}

    return (
      <div className="container-fluid dashboard-element" style={style.wrapper}>
        <div className="p-5" style={{backgroundColor: 'rgba(0, 0, 0, 0.75)', color: '#fff', maxWidth: '600px', minHeight: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '40%'}}>
          <h2>{title.rendered}</h2>
          <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }}></div>
          <Link className="btn btn-outline-light" to={`/section/${slug}`}>Explore</Link>
        </div>
      </div>
    )
  }
}

export default DashboardListItem
