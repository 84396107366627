import { SET_WP_CATS } from "../actionTypes";

const initialState = {
  categories: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_WP_CATS: {
      const { categories } = action.payload;
      return {
        ...state,
        categories
      };
    }
    default:
      return state;
  }
}
