import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class TopicItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  style = {
    button : {
      width: '150px',
      height: '150px',
      backgroundColor: '#25B0E1',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1rem',
      textTransform: 'uppercase',
      color: '#fff',
      fontWeight: '700',
      textAlign: 'center',
      lineHeight: '1.5rem',
      margin: '15px',
      cursor: 'pointer'
    }
  }

  render() {
    const {content, title } = this.props.topic;
    return (
      <div className="topic-modal-btn">
        <div style={this.style.button} onClick={this.toggle}> {title.rendered}</div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>{title.rendered}</ModalHeader>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: content.rendered }}/>
          </ModalBody>
          <ModalFooter>
            <Button color="light" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default TopicItem;
