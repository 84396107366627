import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { logout } from '../redux/actions';

import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import HeaderLinks from './HeaderLinks';

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <div style={{padding: '10px'}}>
        <Navbar  light expand="md">
          <Link className="navbar-brand" to={'/'}>
            <img
              src={process.env.REACT_APP_CLIENT_LOGO}
              className="App-logo"
              alt="logo"
              style={{ width: '300px', height: 'auto' }}
            />
          </Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
          <HeaderLinks/>
          <span className="nav-link" onClick={ () => this.props.logout()}>
                  Signout
          </span>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default withRouter(connect(mapStateToProps, {logout})(Header))
