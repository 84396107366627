import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../redux/actions';

import bgImg from '../img/header-repeating-bg.jpg';

export class Login extends Component {

  state = {
    password: null,
    err: ''
  }

  style = {
    wrapper:{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${bgImg})`
    },
    logo: {
      marginBottom: '80px'
    }
  }

  checkPassword = (e) => {
    e.preventDefault();
    if(this.state.password === this.props.site_password) {
      this.props.login();
    }else {
      this.setState({err: 'incorrect password'})
    }
  }

  render() {
    return (
      <div style={this.style.wrapper}>
        <img src={process.env.REACT_APP_CLIENT_LOGO} alt="client logo" style={this.style.logo}/>
        <form onSubmit={ (e) =>this.checkPassword(e) }>
          <div className="input-group">
            <input className="form-control" type="password" onChange={ (e) => this.setState({ password: e.target.value })}/>
            <div className="input-group-append">
              <input className="btn btn-secondary" type="submit" value="enter" />
            </div>
            
          </div>
        </form>
        <div className="text-danger pt-2">{this.state.err}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  site_password: state.app_settings.site_password
})

export default connect(mapStateToProps, {login})(Login);
