import { ADD_TODO, TOGGLE_TODO, AUTH, SET_DASHBOARD_ELEMENTS, SET_WP_CATS } from "./actionTypes";

let nextTodoId = 0;

export const login = () => ({
  type: AUTH,
  payload: {
    is_auth: true
  }
})

export const logout = () => ({
  type: AUTH,
  payload: {
    is_auth: false
  }
})

export const addTodo = content => ({
  type: ADD_TODO,
  payload: {
    id: ++nextTodoId,
    content
  }
});

export const toggleTodo = id => ({
  type: TOGGLE_TODO,
  payload: { id }
});

export const setDashboardElements = elements => ({
  type: SET_DASHBOARD_ELEMENTS,
  payload: { sections: elements }
})

export const setCategories = categories => ({
  type: SET_WP_CATS,
  payload: { categories: categories }
})