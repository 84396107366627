import React, { Component } from 'react';
import { connect } from 'react-redux';

import axios from 'axios';
import { setDashboardElements } from '../../redux/actions';

import DashboardListItem from './DashboardListItem';
import Loading from '../Loading';


export class Dashboard extends Component {


  componentDidMount() {
    if(!this.props.sections.length ){
      axios.get(`${process.env.REACT_APP_WP_API}/wp-json/wp/v2/pages?_embed&parent=0&orderby=menu_order&order=asc`)
        .then(res=> {
          this.setState({ elements: res.data, isLoaded: true });
          this.props.setDashboardElements(res.data);
        })
        .catch(err=> console.log(err))
    }
  }

  render() {
    let { sections } = this.props;
    if(sections.length){
      return(
        sections.map( section  => (
          <DashboardListItem key={section.id} element={section}/>
        ))
      )
    }
    return ( <Loading />)
  }
}

const mapStateToProps = state => {
  return ({
    sections: state.app_sections.sections
  })
}

export default connect(mapStateToProps, {setDashboardElements} )(Dashboard);
