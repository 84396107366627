import { SET_DASHBOARD_ELEMENTS } from "../actionTypes";

const initialState = {
  sections: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_ELEMENTS: {
      const { sections } = action.payload;
      return {
        ...state,
        sections
      };
    }
    default:
      return state;
  }
}
