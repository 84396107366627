import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from "react-data-grid";
 
class ReportTable extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
  };

  state = {
    data: this.props.data,
    pagedData: this.props.data,
    columns: this.props.columns,
    rowsCount : 25,
    currentPage: 1
  }

  

  compare = function(sortColumn, sortDirection) {
    return function(a, b) {
      let comparison = 0;

      const compareA = ( isNaN(a[sortColumn]) ) ? a[sortColumn] : Number(a[sortColumn]);
      const compareB = b[sortColumn];


  
      if(compareA < compareB) {
        if(sortDirection === "ASC"){
          comparison = -1;
        }else if(sortDirection === "DESC") {
          comparison = 1;
        }
      } else if (compareA > compareB) {
        if(sortDirection === "ASC"){
          comparison = 1;
        }else if(sortDirection === "DESC") {
          comparison = -1;
        }
      }
  
      return comparison;
    }
  }


  sortRows = (data, sortColumn, sortDirection) => {
      let sortedData;

      switch(sortDirection) {
        case "ASC" :
          sortedData = data.sort(this.compare(sortColumn, sortDirection));
          this.setState({data: sortedData, pagedData: sortedData, currentPage: 1 })
          break;
        case "DESC" :
          sortedData = data.sort(this.compare(sortColumn, sortDirection));
          this.setState({data: sortedData, pagedData: sortedData, currentPage: 1})
          break;
        case "NONE" :
          sortedData = data.sort(this.compare('_id', 'ASC'));
          this.setState({data: sortedData, pagedData: sortedData, currentPage: 1})
          break;          
        default: 
          this.setState({ data: data, pagedData: data, currentPage: 1 })
    } 
  };

  paginate(buttonCount){
    let offsetRows = (buttonCount - 1) * this.state.rowsCount;
    let _pagedData = this.props.data.slice(offsetRows);
    this.setState({pagedData: _pagedData, currentPage: buttonCount})
  }
  
  
  render() {
    const { columns, rowsCount, pagedData, currentPage, data} = this.state;

    //Create Pagination buttons
    let rowCount = data.length;
    let buttonCount = 1;
    let offsetAmount = rowsCount;
    const buttons = [];

    //Render prev button
    if(currentPage > 1){
      buttons.push(<li className="page-item" key="prev" onClick={ () => this.paginate(this.state.currentPage - 1)}><span className="page-link">Previous</span></li>)
    }else{
      buttons.push(<li className="page-item disabled" key="prev"><span className="page-link">Previous</span></li>)
    }

    //Render numbered buttons
    while(buttonCount * offsetAmount < rowCount ) {
      let activeClass = (buttonCount === currentPage) ? "page-item active" : "page-item";
      let _btnCount = buttonCount;
      buttons.push(<li className={activeClass} key={buttonCount} onClick={ () => this.paginate(_btnCount)}><span className="page-link">{buttonCount}</span></li>);
      buttonCount++;
    }

    //Render final button if there are rows remaining
    if( rowCount % offsetAmount ){
      let _btnCount = buttonCount;
      let activeClass = (buttonCount === currentPage) ? "page-item active" : "page-item";
      buttons.push(<li className={activeClass} key={_btnCount} onClick={ () => this.paginate(_btnCount)}><span className="page-link">{buttonCount}</span></li>);
      buttonCount++;
    }

    //Render next button
    if(currentPage < buttonCount - 1){
      buttons.push(<li className="page-item" key="next" onClick={ () => this.paginate(this.state.currentPage + 1)}><span className="page-link">Next</span></li>)
    }else{
      buttons.push(<li className="page-item disabled" key="next"><span className="page-link">Next</span></li>)
    }

    //Adjust table height for single paged tables
    let _minHeight = 700;
    let _paginationClass = 'visible';
    let _rowHeight = 26;

    if(data.length < rowsCount){
      _minHeight = (pagedData.length * _rowHeight) + (_rowHeight * 2) ;
      _paginationClass = 'invisible';
    }

  

    return (
      <div>
        <ReactDataGrid 
          columns={columns}
          rowGetter={i => pagedData[i]}
          rowsCount= {rowsCount}
          rowHeight= {_rowHeight}
          minHeight={_minHeight}
          minColumnWidth={40}
          onGridSort={(sortColumn, sortDirection) =>
            this.sortRows(data, sortColumn, sortDirection)
          }
        />

        <nav className={_paginationClass}>
          <ul className="pagination justify-content-center m-5">
            {buttons}
          </ul>
        </nav>

      </div>
    )
  }
}
 
export default ReportTable