
const initialState = {
  wp_url: process.env.REACT_APP_WP_API+'/wp/v2/posts',
  wp_jwt_url: process.env.REACT_APP_WP_API+'/jwt-auth/v1/token',
  site_password: process.env.REACT_APP_PASSWORD
};

export default function(state = initialState, action) {
  return state;
}
