import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

import Loading from '../Loading';



export class PageReports extends Component {
  
  static propTypes = {
    page: PropTypes.object.isRequired
  }

  state = {
    reports: {},
    isLoaded: false,
    slug: this.props.page.slug,
    id: this.props.page.id,
    category: null,
    category_id: null
  }

  componentDidMount() {
    let _slug = this.props.page.slug;
    const section_category = this.props.categories.filter( function(cat) {
      if(cat.slug === _slug){
        return cat;
      }
      return false;
    });

    this.setState({category: section_category[0], category_id: section_category[0].id})

    const getReports = axios.get(`${process.env.REACT_APP_WP_API}/wp-json/wp/v2/reports?categories=${section_category[0].id}`);

    Promise.all([getReports])
      .then( res => {
        this.setState({
          reports: res[0].data,
          isLoaded: true
        });
      })
      .catch( err => console.log(err));
  }


  render() {
    console.log(this.state);

    const {isLoaded, reports } = this.state;

    if(isLoaded){
      return (
        <div className="container-fluid p-5" >
          <div className="row mb-4">
            <div className="col">
              <h2 style={{fontSize:'3rem', fontWeight: '100', textAlign: 'center' }}>Reports</h2>
            </div>
          </div>
          <div className="row my-5" style={{maxWidth: '600px', margin: 'auto'}}>
            {reports.map(report => (
              <Link 
                className="report-btn" 
                to={`/reports/${report.slug}`} 
                key={report.id}
                style={{
                  fontSize:'1.25rem', 
                  fontWeight: '500',
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  backgroundColor: "#25B0E1",
                  color: '#fff',
                  width: '100%',
                  textAlign: 'center',
                  padding: '15px',
                  margin: '15px'
                }}
              >
                {report.title.rendered}
              </Link>
            ))}
          </div>
        </div>
      )
    }

    return ( <Loading />)
  }
}

const mapStateToProps = state => {
  return ({
    categories: state.wp_categories.categories
  })
}

export default connect(mapStateToProps, null)(PageReports)
