import React from 'react';
import bgImg from '../img/header-repeating-bg.jpg';


export default function Loading() {

  const style = {
    wrapper:{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${bgImg})`
    },
    logo: {
      marginBottom: '80px'
    }
  }

  return (
    <div style={style.wrapper}>
      <span className="fa fa-spinner fa-spin fa-2x" style={{ color: '#25B0E1'}}></span>
    </div>
  )
}
